import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import PageTitle from "../../shared/PageTitle/PageTitle";
import Loader from "../../shared/Loader/Loader";
import PopupAds from "./PopupAds/PopupAds";
import TagManager from "react-gtm-module";
import { useLocation } from "react-router-dom";
import utils from "../../../../utils/index";
import Hero from "./Hero/Hero";
import CourseFeature from "./CourseFeature/CourseFeature";

const AllCourses = lazy(() => import("./AllCourses/AllCourses"));
const GoalSet = lazy(() => import("./GoalSet/GoalSet"));
const MojaruWork = lazy(() => import("./MojaruWork/MojaruWork"));
const Ebooks = lazy(() => import("./Ebooks/Ebooks"));
const Success = lazy(() => import("./Success/Success"));
const ThinkAboutMojaru = lazy(() =>
  import("./ThinkAboutMojaru/ThinkAboutMojaru")
);
const MojaruApp = lazy(() => import("./MojaruApp/MojaruApp"));
const Media = lazy(() => import("./Media/Media"));
const CourseTypeCard = lazy(() => import("./CourseTypeCard/CourseTypeCard"));
const DemoClassBooking = lazy(() => import("../DemoClass/DemoClassBooking"));

const Home = () => {
  const [homePageInfo, setHomePageInfo] = useState({});
  const [popUpAds, setPopUpAds] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const demoRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageView",
        pagePath: location.pathname,
        PageTitle: "home",
      },
    });
  }, [location]);

  useEffect(() => {
    let advancedMatching = {};
    if (localStorage.getItem("mobile_or_email")) {
      advancedMatching = {
        ph: Number(localStorage.getItem("mobile_or_email")),
        external_id: localStorage.getItem("user_id"),
      };
    }

    const pixelData = { page: "Home" };
    utils.addFacebookEvent("Page View", pixelData, advancedMatching);
    async function fetchHomePageInfo() {
      let response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/v2/home-page-details`
      );
      response = await response.json();
      setHomePageInfo(response.data);
      setIsLoading(false);
    }

    fetchHomePageInfo();
  }, []);

  useEffect(() => {
    async function fetchPopUp() {
      let response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/pop-up-ads`
      );
      response = await response.json();
      setPopUpAds(response.data);
    }

    fetchPopUp();
    const popupShown = sessionStorage.getItem("popupShown");

    if (!popupShown) {
      fetchPopUp();
      setShowPopup(true);
      sessionStorage.setItem("popupShown", "true");
    }
  }, []);

  // Handle Demo From
  const handelDemoForm = () => {
    if (demoRef.current) {
      demoRef.current.scrollIntoView({ behavior: "smooth" });
      const topPosition =
        demoRef.current.getBoundingClientRect().top + window.scrollY;
      const marginTop = window.innerHeight * 0.1;
      window.scrollTo({
        top: topPosition - marginTop,
        behavior: "smooth",
      });
    }
  };

  if (isLoading) {
    return (
      <>
        <Loader />
      </>
    );
  }

  return (
    // <div className="tw-mt-16 md:tw-mt-[144px] md:tw-w-[fit-content] md:tw-mx-auto">
    <div className="">
      <PageTitle title="Home"></PageTitle>
      <Hero />

      <CourseFeature />

      {popUpAds?.ads?.length > 0 &&
        popUpAds?.ads
          ?.slice(0, 1)
          ?.map((ad) => (
            <PopupAds
              key={ad?.id}
              show={showPopup}
              ad={ad}
              ad_image_path={popUpAds?.ad_image_path}
              onHide={() => setShowPopup(false)}
            />
          ))}

      <Suspense fallback={null}>
        <AllCourses homePageInfo={homePageInfo} />
      </Suspense>

      <Suspense fallback={null}>
        <GoalSet />
      </Suspense>

      <Suspense fallback={null}>
        <MojaruWork handelDemoForm={handelDemoForm} />
      </Suspense>

      <Suspense fallback={null}>
        <CourseTypeCard />
      </Suspense>

      <Suspense fallback={null}>
        <Ebooks homePageInfo={homePageInfo} />
      </Suspense>

      <Suspense fallback={null}>
        <Success />
      </Suspense>

      <Suspense fallback={null}>
        <ThinkAboutMojaru />
      </Suspense>

      <Suspense fallback={null}>
        <MojaruApp />
      </Suspense>

      <Suspense fallback={null}>
        <div ref={demoRef} className="tw-mt-16">
          <DemoClassBooking />
        </div>
      </Suspense>

      <Suspense fallback={null}>
        <Media homePageInfo={homePageInfo} />
      </Suspense>
    </div>
  );
};

export default Home;
