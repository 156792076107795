import React from "react";
import "./CourseFeature.css";
import cursorIcon from "../../../../../images/HomePageImage/IconWrapper.png";
import link from "../../../../../images/HomePageImage/External_link.png";
import manIcon from "../../../../../images/HomePageImage/man.svg";
import book from "../../../../../images/HomePageImage/book.svg";
import playIcon from "../../../../../images/HomePageImage/player.svg";
import paperIcon from "../../../../../images/HomePageImage/paper.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CourseFeature = () => {
  const { t } = useTranslation();
  return (
    <div className="md:tw-max-w-[1440px] tw-w-full tw-p-4 2xl:tw-p-0 tw-mx-auto">
      {/* Full container card and text  */}
      {/* <div className="tw-w-full course-feature tw-py-10 md:tw-py-16 md:tw-mx-auto md:tw-max-w-[1440px]"> */}
      <div className="tw-w-full course-feature md:tw-mx-auto tw-max-w-[1440px]">
        {/* Grid container for the whole section */}

        {/* <div className=" tw-flex-none md:tw-flex  tw-gap-8 md:tw-gap-0 tw-items-center md:tw-px-16"> */}
        <div className=" tw-flex-none md:tw-flex tw-gap-8 md:tw-gap-0 md:tw-items-center lg:tw-items-start  md:tw-px-8 lg:tw-px-16">
          {/* Why Mojaru is Unique Section */}
          <div className="tw-flex tw-flex-col tw-items-center md:tw-items-start md:tw-mr-4">
            <img
              className="tw-w-16 tw-h-16 tw-mt-10"
              src={cursorIcon}
              alt="icon"
            />
            <h2 className="tw-text-3xl md:tw-text-4xl tw-font-bold tw-text-center md:tw-text-left tw-mt-2 tw-text-gray-700">
              {t("Why")}{" "}
              <span className="tw-text-[#6F39B7]">{t("Mojaru is")}</span>{" "}
              {t("Unique")}
            </h2>
            <p className="tw-text-center md:tw-text-left tw-mt-4">
              {t("Why Mojaru is Unique description")}
            </p>
            <Link to="/all">
              <button className="tw-w-full md:tw-maxw-[160px] tw-flex tw-justify-center tw-mt-8 tw-bg-[#6F39B7] tw-py-3 tw-rounded-3xl tw-px-4">
                <span className="tw-text-white tw-font-bold tw-mr-2">
                  {t("All courses")}
                </span>
                <img src={link} alt="link icon" />
              </button>
            </Link>
          </div>

          {/* Card Section */}
          <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-16 tw-mt-16 md:tw-mt-0 md:tw-gap-4 tw-pb-9 md:tw-py-16">
            {/* card 1 */}
            <div className="tw-bg-white tw-relative tw-p-6 tw-rounded-3xl tw-shadow-md">
              <img
                className="tw-absolute -tw-top-12"
                src={manIcon}
                alt="icon"
              />
              <div className="tw-mt-10 md:tw-mt-12">
                <h3 className="tw-text-lg tw-text-gray-900">
                  {t("Student-Friendly Teachers")}
                </h3>
                <p className="tw-text-gray-500 tw-mt-3">
                  {t("Student-Friendly Teachers description")}
                </p>
              </div>
            </div>
            {/* card 2 */}
            <div className="tw-bg-white tw-relative tw-p-6 tw-rounded-3xl tw-shadow-md">
              <img className="tw-absolute -tw-top-12" src={book} alt="icon" />
              <div className="tw-mt-10 md:tw-mt-12">
                <h3 className="tw-text-lg tw-text-gray-900">
                  {t("Fun Learning Materials")}
                </h3>
                <p className="tw-text-gray-500 tw-mt-3">
                  {t("Fun Learning Materials description")}
                </p>
              </div>
            </div>
            {/* card 3 */}
            <div className="tw-bg-white tw-relative tw-p-6 tw-rounded-3xl md:tw-mt-12 tw-shadow-md">
              <img
                className="tw-absolute -tw-top-12"
                src={playIcon}
                alt="icon"
              />
              <div className="tw-mt-10 md:tw-mt-12">
                <h3 className="tw-text-lg tw-text-gray-900">
                  {t("Interactive Classes")}
                </h3>
                <p className="tw-text-gray-500 tw-mt-3">
                  {t("Interactive Classes description")}
                </p>
              </div>
            </div>
            {/* card 4 */}
            <div className="tw-bg-white tw-relative tw-p-6 tw-rounded-3xl md:tw-mt-10 tw-shadow-md">
              <img
                className="tw-absolute -tw-top-12"
                src={paperIcon}
                alt="icon"
              />
              <div className="tw-mt-10 md:tw-mt-12">
                <h3 className="tw-text-lg tw-text-gray-900">
                  {t("Customised Education")}
                </h3>
                <p className="tw-text-gray-500 tw-mt-3">
                  {t("Customised Education description")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseFeature;

// {/* <div className="tw-w-full course-feature md:tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-start">
// {/* why mojaru better from others */}
// <div className="md:tw-w-[38%] md:tw-mr-[4%] tw-flex tw-flex-col tw-items-center md:tw-items-start tw-py-10 md:tw-py-16 md:tw-pl-16">
//   <img
//     className="tw-w-16 tw-h-16 tw-mt-10"
//     src={cursorIcon}
//     alt="icon"
//   />
//   <h2 className="tw-text-3xl md:tw-text-4xl tw-font-bold tw-text-center md:tw-text-left tw-mt-2 tw-text-gray-700">
//     {t("Why")}{" "}
//     <span className="tw-text-[#6F39B7]">{t("Mojaru is")}</span>{" "}
//     {t("Unique")}
//   </h2>
//   <p className="tw-text-left tw-mt-4">
//     {t("Why Mojaru is Unique description")}
//   </p>
//   <Link to="/all">
//     <button className="tw-w-full md:tw-maxw-[160px] tw-flex tw-justify-center tw-mt-8 tw-bg-[#6F39B7] tw-py-3 tw-rounded-3xl tw-px-4">
//       <span className="tw-text-white tw-font-bold tw-mr-2">
//         {t("All courses")}
//       </span>
//       <img src={link} alt="link icon" />
//     </button>
//   </Link>
// </div>

// {/* Card section  */}
// <div className=" tw-mt-16 md:tw-mt-12 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 md:tw-gap-4 tw-pb-9 md:tw-py-16 md:tw-pr-16">
//   <div className="tw-bg-white tw-relative md:tw-mt-16 tw-p-6 tw-rounded-3xl tw-shadow-md">
//     <img className="tw-absolute -tw-top-12" src={manIcon} alt="icon" />
//     <div className="tw-mt-10 md:tw-mt-12">
//       <h3 className="tw-text-lg tw-text-gray-900">
//         {t("Student-Friendly Teachers")}
//       </h3>
//       <p className="tw-text-gray-500 tw-mt-3">
//         {t("Student-Friendly Teachers description")}
//       </p>
//     </div>
//   </div>
//   <div className="tw-bg-white tw-mt-16 tw-relative tw-p-6 tw-rounded-3xl tw-shadow-md">
//     <img className="tw-absolute -tw-top-12" src={book} alt="icon" />
//     <div className="tw-mt-10 md:tw-mt-12">
//       <h3 className="tw-text-lg tw-text-gray-900">
//         {t("Fun Learning Materials")}
//       </h3>
//       <p className="tw-text-gray-500 tw-mt-3">
//         {t("Fun Learning Materials description")}
//       </p>
//     </div>
//   </div>
//   <div className="tw-bg-white tw-mt-16 tw-relative tw-p-6 tw-rounded-3xl tw-shadow-md">
//     <img className="tw-absolute -tw-top-12" src={playIcon} alt="icon" />
//     <div className="tw-mt-10 md:tw-mt-12">
//       <h3 className="tw-text-lg tw-text-gray-900">
//         {t("Interactive Classes")}
//       </h3>
//       <p className="tw-text-gray-500 tw-mt-3">
//         {t("Interactive Classes description")}
//       </p>
//     </div>
//   </div>
//   <div className="tw-bg-white tw-mt-16 tw-relative tw-p-6 tw-rounded-3xl tw-shadow-md">
//     <img
//       className="tw-absolute -tw-top-12"
//       src={paperIcon}
//       alt="icon"
//     />
//     <div className="tw-mt-10 md:tw-mt-12">
//       <h3 className="tw-text-lg tw-text-gray-900">
//         {t("Customised Education")}
//       </h3>
//       <p className="tw-text-gray-500 tw-mt-3">
//         {t("Customised Education description")}
//       </p>
//     </div>
//   </div>
// </div>
// </div> */}
