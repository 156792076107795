import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./Registration.css";
import PageTitle from "../../shared/PageTitle/PageTitle";
import ReactGA from "react-ga4";
import mixpanel from "mixpanel-browser";
import TagManager from "react-gtm-module";
import { useLocation } from "react-router-dom";
import utils from "../../../../utils/index";
import bg from "../../../../images/login/BG.png";
import successStudent from "../../../../images/login/successStudent.png";
import SliderComponent from "../SliderComponent/SliderComponent";
import image1 from "../../../../images/HomePageImage/success/16x9a.jpg";
import image2 from "../../../../images/HomePageImage/success/9x16a.jpg";
import image3 from "../../../../images/HomePageImage/success/1a.jpg";
import { useTranslation } from "react-i18next";
import Navbar from "../../shared/Header/Navbar";

const Registration = () => {
  const location = useLocation();
  const path = location.pathname;
  const [btnLoading, setBtnLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isValidInput, setIsValidInput] = useState(false);
  let navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageView",
        pagePath: location.pathname,
        PageTitle: "Registration",
      },
    });
  }, [location]);

  const validateInput = (value) => {
    const phoneRegex = /^01[3-9]\d{8}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (phoneRegex.test(value) || emailRegex.test(value)) {
      setIsValidInput(true);
    } else {
      setIsValidInput(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    validateInput(value);
  };

  const handelRegistration = (event) => {
    event.preventDefault();

    ReactGA.event({
      category: "registration",
      action: "registration-action",
      label: "registration-level",
      value: parseInt(event.target.mobile_or_email.value),
    });

    const pixelData = { page: "Registration" };
    utils.addFacebookEvent("Registration", pixelData);

    mixpanel.track("Registration", {
      number: parseInt(event.target.mobile_or_email.value),
    });

    setBtnLoading(true);

    const user_information = {
      mobile_or_email: event.target.mobile_or_email.value,
    };

    fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}registration`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(user_information),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message_type === "success") {
          navigate("/otp?page=sign-up", {
            state: {
              mobile_or_email: event.target.mobile_or_email.value,
              user_id: data.data.user_id,
              message: data.message,
              from: "sing-up",
            },
          });
          setBtnLoading(false);
        } else {
          toast.error(data.message);
          setBtnLoading(false);
        }
      });
  };

  return (
    <>
      <Navbar location={location.pathname} />
      <section className="tw-h-screen tw-flex tw-items-center tw-justify-center -tw-mt-[88px]">
        <PageTitle title="Registration"></PageTitle>
        <div className=" md:tw-flex tw-w-full tw-h-full">
          <div className="tw-w-full md:tw-w-1/2 tw-h-full tw-flex tw-flex-col tw-justify-center tw-px-4 md:tw-px-0">
            <div className="tw-w-full md:tw-w-[50%] tw-mx-auto">
              <h2 className="tw-text-2xl tw-font-bold tw-text-center md:tw-text-left">
                {t("Welcome To Mojaru!")}
              </h2>
              <p className="tw-mt-2 tw-text-gray-600 tw-text-center md:tw-text-left">
                {t("Join Mojaru")}
              </p>
              <form onSubmit={handelRegistration} className="tw-mt-8">
                <label
                  htmlFor="mobile_or_email"
                  className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-700"
                >
                  {t("Enter Your Phone Number/ Email")}
                </label>
                <input
                  id="mobile_or_email"
                  name="mobile_or_email"
                  type="text"
                  value={inputValue}
                  onChange={handleInputChange}
                  placeholder={t("Your Phone Number/ Email")}
                  className="tw-block tw-w-full tw-mt-1 tw-rounded-md tw-border-0 tw-pl-4 tw-py-3 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-orange-200 tw-sm:tw-text-sm tw-sm:tw-leading-6"
                />

                <button
                  type="submit"
                  className={`tw-mt-6 tw-w-full tw-py-3 tw-rounded-3xl tw-text-white ${
                    !isValidInput
                      ? "tw-cursor-not-allowed tw-bg-[#D2BEF4]"
                      : "tw-bg-[#532D80]"
                  }`}
                  disabled={!isValidInput || btnLoading}
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
          
          <div className="tw-hidden md:tw-block md:tw-w-1/2 tw-h-full tw-relative">
            <img
              className="tw-w-full tw-h-full tw-object-cover"
              src={bg}
              alt="background image"
            />
            <div className=" tw-absolute tw-top-[30%] tw-left-[10%] tw-w-[80%] tw-mx-auto tw-bg-white tw-p-8 tw-rounded-3xl">
              <div className="tw-flex tw-justify-between">
                <img
                  className="tw-min-w-[250px] tw-rounded-2xl"
                  src={image1}
                  alt="icon"
                />
                <img
                  className="tw-w-[100px] tw-mx-2 tw-rounded-2xl md:tw-hidden lg:tw-flex"
                  src={image2}
                  alt="icon"
                />
                <img
                  className="tw-w-[210px] tw-rounded-2xl md:tw-hidden lg:tw-flex"
                  src={image3}
                  alt="icon"
                />
              </div>
              <div className="tw-overflow-hidden tw-mt-8">
                <SliderComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Registration;
