import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import "./CourseReview.css";
import Review from "./Review/Review";
import { englishToBengaliNumberFormat } from "../../../../../../utilities/utilities";
import { CourseDetailContext } from "../../CourseDetails";
import Rating from "../../../Rating/Rating";
import { ArrowDownIcon } from "@heroicons/react/16/solid";

const CourseReview = ({ course_id, isCoursePurchase }) => {
    const [reviews, setReviews] = useState([]);
    const [rating, setRating] = useState(5);
    const [showAll, setShowAll] = useState(false); // State to control showing all reviews
    const courseDetail = useContext(CourseDetailContext); // Moved this up for better visibility

    const fetchReview = async () => {
        let response = await fetch(
            `${process.env.REACT_APP_BASE_URL}api/course-reviews?course_id=${course_id}`
        );
        response = await response.json();
        setReviews(response);
    };

    useEffect(() => {
        fetchReview();
    }, [course_id]);

    const handleReview = (event) => {
        event.preventDefault();

        const reviewDetails = {
            user_name: 'Mojaru',
            review_detail: event.target.opinion.value,
            ratings: event.target.ratings.value,
            user_id: localStorage.getItem('user_id'),
            course_id: course_id,
            course_name: courseDetail?.course_detail?.course_name // using context api
        };

        fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}post-course-review`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
                'accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("auth_token")}`,
                'SecretKey': `${process.env.REACT_APP_API_URL_SECRET_KEY}`
            },
            body: JSON.stringify(reviewDetails)
        })
            .then(res => res.json())
            .then(data => {
                if (data.message_type === 'success') {
                    toast.success(data.message);
                    event.target.reset();
                    fetchReview();
                } else {
                    toast.error(data.message);
                    event.target.reset();
                }
            });
    };

    // Handle showing all reviews
    const handleShowAllReviews = () => {
        setShowAll(true);
    };

    const displayedReviews = showAll ? reviews.data : reviews.data?.slice(0, 2);

    return (
        <div className="review-Section">
            <button className="accordion review-btn btn-items">
                <div className="btn-items">
                    <div id="review_text">লার্নারস রিভিউ (<span className="review-count balooda-medium">{englishToBengaliNumberFormat(reviews.total_reviews)}</span>)</div>
                    <div>
                        <span id="write_review">রিভিউ লিখুন</span>
                    </div>
                </div>

                {isCoursePurchase ? <p className="review-btn rounded-3 text_color_org a_tag_decoration button_border mb-0">রিভিউ লিখুন</p> : ""}
            </button>
            <div className="panel">
                <Form onSubmit={handleReview}>
                    <div className="review-divider"></div>
                    <div className="review-input-field">
                        <textarea
                            id='message'
                            className="form-control mt-2"
                            name="opinion"
                            placeholder="তোমার বার্তা"
                            rows="4"
                        ></textarea>
                    </div>
                    <div className="mt-3 d-flex align-items-center">
                        <Rating rating={rating} onRating={(rate) => setRating(rate)} color={{ filled: "#FB923C", unfilled: "#DCDCDC" }} />
                        <p className="mb-0 ms-1" style={{ color: '#7C7C7C', fontSize: '22px' }}>({rating})</p>
                    </div>

                    <input type='hidden' name='ratings' id="rating" value={rating} />

                    <div className="d-flex justify-content-center">
                        <button className="px-5 py-2 rounded-3 btn-review-submit mt-3">
                            সাবমিট করুন
                        </button>
                    </div>
                </Form>
            </div>

            <div id="show">লার্নারস রিভিউ (<span className="review-count balooda-medium">{englishToBengaliNumberFormat(reviews.total_reviews)}</span>)</div>
            <div className="tw-grid tw-grid-cols-2 tw-gap-3">
                {
                    reviews.total_reviews > 0 ?
                        displayedReviews.map(review => (<Review key={review.review_id} review={review} path={reviews.user_image_path}></Review>))
                        :
                        <p className="text-center reviewer_name mt-5" style={{ fontSize: '26px' }}>এই কোর্সে এখনও কোন রিভিউ প্রদান করা হয়নি।</p>
                }
            </div>
            {reviews.total_reviews > 2 && !showAll && (
                <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-mt-4">
                    <button className="tw-flex tw-text-[#532D80] tw-text-lg tw-font-bold" onClick={handleShowAllReviews}>
                        <span className="tw-mr-2">আরো দেখুন</span> <ArrowDownIcon className="tw-w-5 tw-mt-1"/>
                    </button>
                </div>
            )}
        </div>
    );
};

export default CourseReview;
