import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';

const PopupAds = (props) => {
  const [show, setShow] = useState(true);

  // Close the modal after 4 seconds
  useEffect(() => {
      const timer = setTimeout(() => {
          setShow(false);
      }, 20000);

      return () => clearTimeout(timer);
  }, []); 
    return (
        <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show} 
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" style={{fontWeight: "700"}}>
          {props?.ad?.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

      {
        props?.ad?.ads_type === 1 ? 

        <iframe
            style={{ borderRadius: "16px" }}
            width="100%"
            height="480"
            src={`https://www.youtube.com/embed/${props?.ad?.youtube_link_id}?controls=1`}
            title="about-video"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        ></iframe>

        :
        parseInt(props?.ad?.ads_type) === 2 ?
        <div className='d-flex justify-content-center'>
            
           <a
            target="_blank"
            rel="noreferrer"
            href={props?.ad?.ads_link}
        >
            <img src={`${props?.ad_image_path}${props?.ad?.ads_image}`} alt="" className='img-fluid' style={{ borderRadius:'16px' }} />
           </a> 

        </div>
        :

        <p style={{ fontSize:'18px' }}>
            {props?.ad?.text_content}
        </p>
      }
        {/* <div className='d-flex justify-content-center'>
           <Link to='google.com'>

            <img src="https://mojaru-prod.s3.ap-southeast-1.amazonaws.com/courses/VwKf9NaTjgkvKoq8j2prAW2fl2OqOlZpkM0eornx.png" alt="" className='img-fluid' style={{ borderRadius:'8px' }} />
           </Link> 
        </div> */}
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer> */}
    </Modal>
    );
};

export default PopupAds;