import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./StudentFeedback.css";
import iconImg from "../../../../images/feedback-icon.png";
const StudentFeedback = ({
  pendingFeedbackData,
  showModal,
  feedbackQuestion,
}) => {
  const userId = localStorage.getItem("user_id");
  const auth_token = localStorage.getItem("auth_token");
  const [data, setData] = useState({
    live_class_id: pendingFeedbackData?.live_class_id,
    attendance_id: pendingFeedbackData?.attendance_id,
    user_id: userId,
    is_class_good: 0,
    teacher_camera_on_whole_class: 0,
    teacher_talk_with_you: 0,
    teacher_give_hw_mark: 0,
    teacher_take_fulltime_class: 0,
    feedback_comment: "",
  });
  const handleChange = ({ currentTarget: input }) => {
    setData({ ...data, [input.name]: input.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}api/student/v2/feedback-save/${userId}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${auth_token}`,
            SecretKey: process.env.REACT_APP_API_URL_SECRET_KEY,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const apiResponse = await response.json();
      if (apiResponse.success === true) {
        window.location.reload();
      }
    } catch (error) {
      // console.error("Error fetching feedback data:", error);
    }
  };

  return (
    <Modal className="tw-top-[10px] tw-left-[100]" show={showModal}>
      <Modal.Body className="tw-p-1 md:tw-p-1 lg:tw-p-[24px] tw-gap-[24px]  tw-w-[450px] lg:tw-w-[515px]">
        <div className="container">
          <div className="row">
            <div className="col-12 tw-p-[24px] tw-mb-5">
              <div className="d-flex tw-gap-[8px]">
                <img
                  src={iconImg}
                  alt="icon"
                  className="topImgIcon tw-w-[48px] tw-h-[48px]"
                />
                <div>
                  <h6 className="tw-mb-2 tw-text-[18px] tw-leading-[24px] tw-font-[600] tw-text-[#1F2937] tw-w-[403px] tw-h-[24px] topTitle">
                    ক্লাসের সম্পর্কে তোমার মতামত দাও!
                  </h6>
                  <p className="tw-w-[374px] tw-h-[39px] tw-font-[400] tw-text-[14px] tw-leading-[20px] tw-text-[#6B7280] topDescription">
                    আজকের ক্লাস কেমন লাগলো তোমার? জানিয়ে দাও এখনই! যাতে আমরা
                    পরবর্তী ক্লাস আরো ভালো করতে পারি।
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="tw-card tw-w-3/4 md:tw-w-3/4 lg:tw-w-full tw-rounded-md tw-bg-gray-100 tw-p-2">
                <p className="tw-w-[300px] lg:tw-w-[374px] tw-font-[400] tw-text-[14px] tw-leading-[20px] tw-text-[#6B7280]">
                  <span className="tw-font-[700] tw-mx-2">কোর্সঃ</span>{" "}
                  {pendingFeedbackData?.course_name} <br />
                  <span className="tw-font-[700] tw-mx-2">ব্যাচঃ</span>{" "}
                  {pendingFeedbackData?.batch_name} <br />
                  <span className="tw-font-[700] tw-mx-2">বিষয়ঃ</span>{" "}
                  {pendingFeedbackData?.subject_name}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 tw-p-[24px]">
          <hr className="tw-w-[300px] lg:tw-w-[420px] tw-border-[0.5px] tw-border-[#E5E7EB]" />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 tw-gap-[12px] tw-px-[24px]">
                <div>
                  <p className="tw-h-[21px] tw-font-[400] tw-text-[14px] tw-leading-[20px] tw-mb-[22px]">
                    {feedbackQuestion?.[0]?.question}
                    <br />
                  </p>
                  <div className="tw-flex">
                    <div className="tw-flex">
                      <input
                        type="radio"
                        id="classFeedbackYes"
                        name="is_class_good"
                        value={1}
                        onChange={handleChange}
                        required
                        className="tw-accent-[#6F39B7] tw-w-[20px] tw-h-[20px]"
                      />
                      <label className="tw-w-[44px] tw-h-[20px] tw-font-[500] tw-text-[14px] tw-leading-[20px] tw-mx-1">
                        হ্যাঁ
                      </label>
                    </div>
                    <div className="tw-flex">
                      <input
                        type="radio"
                        id="classFeedbackNo"
                        name="is_class_good"
                        value={0}
                        onChange={handleChange}
                        required
                        className="tw-accent-[#6F39B7] tw-w-[20px] tw-h-[20px]"
                      />
                      <label className="tw-w-[44px] tw-h-[20px] tw-font-[500] tw-text-[14px] tw-leading-[20px] tw-mx-1">
                        না
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 tw-p-[24px]">
                <hr className="tw-w-[300px] lg:tw-w-[420px] tw-border-[0.5px] tw-border-[#E5E7EB]" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 tw-gap-[12px] tw-px-[24px]">
                <div>
                  <p className="tw-h-[21px] tw-font-[400] tw-text-[14px] tw-leading-[20px] tw-mb-[22px]">
                    {feedbackQuestion?.[1]?.question}
                    <br />
                  </p>
                  <div className="tw-flex">
                    <div className="tw-flex">
                      <input
                        type="radio"
                        id="classFeedbackYes"
                        name="teacher_camera_on_whole_class"
                        value={1}
                        onChange={handleChange}
                        required
                        className="tw-accent-[#6F39B7] tw-w-[20px] tw-h-[20px]"
                      />
                      <label className="tw-w-[44px] tw-h-[20px] tw-font-[500] tw-text-[14px] tw-leading-[20px] tw-mx-1">
                        হ্যাঁ
                      </label>
                    </div>
                    <div className="tw-flex">
                      <input
                        type="radio"
                        id="classFeedbackNo"
                        name="teacher_camera_on_whole_class"
                        value={0}
                        onChange={handleChange}
                        required
                        className="tw-accent-[#6F39B7] tw-w-[20px] tw-h-[20px]"
                      />
                      <label className="tw-w-[44px] tw-h-[20px] tw-font-[500] tw-text-[14px] tw-leading-[20px] tw-mx-1">
                        না
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 tw-p-[24px]">
                <hr className="tw-w-[300px] lg:tw-w-[420px] tw-border-[0.5px] tw-border-[#E5E7EB]" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 tw-gap-[12px] tw-px-[24px]">
                <div>
                  <p className="tw-h-[21px] tw-font-[400] tw-text-[14px] tw-leading-[20px] tw-mb-[22px]">
                    {feedbackQuestion?.[2]?.question}
                    <br />
                  </p>
                  <div className="tw-flex">
                    <div className="tw-flex">
                      <input
                        type="radio"
                        id="classFeedbackYes"
                        name="teacher_talk_with_you"
                        value={1}
                        onChange={handleChange}
                        required
                        className="tw-accent-[#6F39B7] tw-w-[20px] tw-h-[20px]"
                      />
                      <label className="tw-w-[44px] tw-h-[20px] tw-font-[500] tw-text-[14px] tw-leading-[20px] tw-mx-1">
                        হ্যাঁ
                      </label>
                    </div>
                    <div className="tw-flex">
                      <input
                        type="radio"
                        id="classFeedbackNo"
                        name="teacher_talk_with_you"
                        value={0}
                        onChange={handleChange}
                        required
                        className="tw-accent-[#6F39B7] tw-w-[20px] tw-h-[20px]"
                      />
                      <label className="tw-w-[44px] tw-h-[20px] tw-font-[500] tw-text-[14px] tw-leading-[20px] tw-mx-1">
                        না
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 tw-p-[24px]">
                <hr className="tw-w-[300px] lg:tw-w-[420px] tw-border-[0.5px] tw-border-[#E5E7EB]" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 tw-gap-[12px] tw-px-[24px]">
                <div>
                  <p className="tw-h-[21px] tw-font-[400] tw-text-[14px] tw-leading-[20px] tw-mb-[22px]">
                    {feedbackQuestion?.[3]?.question}
                    <br />
                  </p>
                  <div className="tw-flex">
                    <div className="tw-flex">
                      <input
                        type="radio"
                        id="classFeedbackYes"
                        name="teacher_give_hw_mark"
                        value={1}
                        onChange={handleChange}
                        required
                        className="tw-accent-[#6F39B7] tw-w-[20px] tw-h-[20px]"
                      />
                      <label className="tw-w-[44px] tw-h-[20px] tw-font-[500] tw-text-[14px] tw-leading-[20px] tw-mx-1">
                        হ্যাঁ
                      </label>
                    </div>
                    <div className="tw-flex">
                      <input
                        type="radio"
                        id="classFeedbackNo"
                        name="teacher_give_hw_mark"
                        value={0}
                        onChange={handleChange}
                        required
                        className="tw-accent-[#6F39B7] tw-w-[20px] tw-h-[20px]"
                      />
                      <label className="tw-w-[44px] tw-h-[20px] tw-font-[500] tw-text-[14px] tw-leading-[20px] tw-mx-1">
                        না
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 tw-p-[24px]">
                <hr className="tw-w-[300px] lg:tw-w-[420px] tw-border-[0.5px] tw-border-[#E5E7EB]" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-xl-6 col-md-12 col-sm-12 tw-gap-[12px] tw-px-[24px] tw-pb-[15px]">
                <div>
                  <p className="tw-h-[21px] tw-font-[400] tw-text-[14px] tw-leading-[20px]">
                    {feedbackQuestion?.[4]?.question}
                    <br />
                  </p>
                  <div className="tw-flex">
                    <div className="tw-flex">
                      <input
                        type="radio"
                        id="classFeedbackYes"
                        name="teacher_take_fulltime_class"
                        value={1}
                        onChange={handleChange}
                        required
                        className="tw-accent-[#6F39B7] tw-w-[20px] tw-h-[20px]"
                      />
                      <label className="tw-w-[44px] tw-h-[20px] tw-font-[500] tw-text-[14px] tw-leading-[20px] tw-mx-1">
                        হ্যাঁ
                      </label>
                    </div>
                    <div className="tw-flex">
                      <input
                        type="radio"
                        id="classFeedbackNo"
                        name="teacher_take_fulltime_class"
                        value={0}
                        onChange={handleChange}
                        required
                        className="tw-accent-[#6F39B7] tw-w-[20px] tw-h-[20px]"
                      />
                      <label className="tw-w-[44px] tw-h-[20px] tw-font-[500] tw-text-[14px] tw-leading-[20px] tw-mx-1">
                        না
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 tw-p-[24px]">
                <hr className="tw-w-[300px] lg:tw-w-[420px] tw-border-[0.5px] tw-border-[#E5E7EB]" />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 tw-gap-[12px] tw-px-[24px]">
                <div className="tw-mb-3 tw-w-3/4 lg:tw-w-full">
                  <textarea
                    className="form-control tw-font-[400] tw-placeholder-opacity-15 tw-text-sm"
                    name="feedback_comment"
                    id="feedback_comment"
                    placeholder="আরো কিছু বলতে চাও?"
                    onChange={handleChange}
                    value={data.feedback_comment}
                    rows="4"
                  ></textarea>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 tw-relative lg:tw-flex lg:tw-justify-center lg:tw-items-center">
                <button
                  type="submit"
                  className=" tw-bg-[#532D80] tw-w-3/4 lg:tw-w-[425px] tw-h-[38px] tw-px-[4px] lg:tw-px-[17px] tw-py-[9px] tw-rounded-[6px] submitButton tw-mt-8"
                >
                  <span className="tw-text-white">সাবমিট করো</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default StudentFeedback;
