import React, { useState, useMemo, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { toast } from "react-toastify";
import "./Login.css";
import bg from "../../../../images/login/BG.png";
import PageTitle from "../../shared/PageTitle/PageTitle";
import ReactGA from "react-ga4";
import mixpanel from "mixpanel-browser";
import SliderComponent from "../SliderComponent/SliderComponent";
import successStudent from "../../../../images/login/successStudent.png";
import image1 from "../../../../images/HomePageImage/success/16x9a.jpg";
import image2 from "../../../../images/HomePageImage/success/9x16a.jpg";
import image3 from "../../../../images/HomePageImage/success/1a.jpg";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const Login = () => {
  const { t } = useTranslation();
  const [language, setLanguage] = useState("bn");
  const [btnLoading, setBtnLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isValidInput, setIsValidInput] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const memoizedData = useMemo(() => {
    return location.state?.from;
  }, []);

  const validateInput = (value) => {
    const phoneRegex = /^01[3-9]\d{8}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (phoneRegex.test(value) || emailRegex.test(value)) {
      setIsValidInput(true);
    } else {
      setIsValidInput(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    validateInput(value);
  };

  const handelLogIn = (event) => {
    event.preventDefault();

    ReactGA.event({
      category: "login",
      action: "login-action",
      label: "login-level",
      value: parseInt(event.target.mobile_or_email.value),
    });

    mixpanel.track("Login", {
      number: parseInt(event.target.mobile_or_email.value),
    });

    setBtnLoading(true);
    const login_info = {
      mobile_or_email: event.target.mobile_or_email.value,
    };

    fetch(`${process.env.REACT_APP_BASE_URL_STUDENT}login`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(login_info),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message_type === "success") {
          navigate("/otp?page=login", {
            state: {
              mobile_or_email: event.target.mobile_or_email.value,
              user_id: data.data.user_id,
              message: data.message,
              from: "login",
            },
          });
          setBtnLoading(false);
        } else {
          toast.error(data.message);
          setBtnLoading(false);
        }
      });
  };

  // Language switcher
  useEffect(() => {
    const useLanguage = sessionStorage.getItem("useLanguage");
    if (!useLanguage) {
      sessionStorage.setItem("useLanguage", "bn");
      i18next.changeLanguage("bn");
      setLanguage("bn");
    } else {
      i18next.changeLanguage(useLanguage);
      setLanguage(useLanguage);
    }
  }, []);

  return (
    <section className="tw-h-screen tw-flex tw-items-center tw-justify-center -tw-mt-[88px]">
      <PageTitle title="Login"></PageTitle>
      <div className="md:tw-flex tw-w-full tw-h-full tw-items-center">
        {/* Left side  */}
        <div className="tw-w-full md:tw-w-1/2 tw-h-full tw-flex tw-flex-col tw-justify-center">
          <div className="tw-w-[90%] md:tw-w-[50%] tw-mx-auto">
            <h2 className="tw-text-2xl tw-font-bold">
              {t("Log in with your email or phone number")}
            </h2>
            <p className="tw-mt-2 tw-text-gray-600">
              <Link to="/sign-up">
                <span className=" tw-text-[#6F39B7]">
                  {t("Don’t have an account")}
                  <span className=" tw-border-b tw-border-blue-500 tw-text-blue-500 tw-font-semibold">
                    {t("Register Now")}
                  </span>
                </span>
              </Link>
            </p>
            <form onSubmit={handelLogIn} className="tw-mt-8">
              <label
                htmlFor="mobile_or_email"
                className="tw-block tw-text-sm tw-font-medium tw-leading-6 tw-text-gray-700"
              >
                {t("Email/Phone number")}
              </label>
              <input
                id="mobile_or_email"
                name="mobile_or_email"
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                placeholder={t("Email/Phone number")}
                className="tw-block tw-w-full tw-mt-1 tw-rounded-md tw-border-0 tw-pl-4 tw-py-3 tw-text-gray-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-gray-300 tw-placeholder:text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-orange-200 tw-sm:tw-text-sm tw-sm:tw-leading-6"
              />

              <button
                type="submit"
                className={`tw-mt-6 tw-w-full tw-py-3 tw-rounded-3xl tw-text-white ${
                  !isValidInput
                    ? "tw-cursor-not-allowed tw-bg-[#D2BEF4]"
                    : "tw-bg-[#532D80]"
                }`}
                disabled={!isValidInput || btnLoading}
              >
                {t("Submit")}
              </button>
            </form>
          </div>
        </div>

        {/* Right Side  */}
        <div className="tw-hidden md:tw-w-1/2 tw-h-full tw-relative md:tw-block">
          <img
            className="tw-w-full tw-h-full tw-object-cover"
            src={bg}
            alt="background image"
          />

          <div className=" tw-absolute tw-top-[30%] tw-left-[10%] tw-w-[80%] tw-mx-auto  tw-bg-white tw-p-8 tw-rounded-3xl">
            <div className="tw-flex tw-justify-between ">
              <div>
                <img
                  className="tw-w-[350px] tw-h-full tw-rounded-3xl"
                  src={image1}
                  alt="icon"
                />
              </div>
              <div className="md:tw-hidden lg:tw-block">
                <img
                  className="tw-w-[100px] tw-h-full tw-rounded-3xl"
                  src={image2}
                  alt="icon"
                />
              </div>
              <div className="md:tw-hidden lg:tw-block">
                <img
                  className="tw-w-[210px] tw-rounded-3xl"
                  src={image3}
                  alt="icon"
                />
              </div>
            </div>
            <div className="tw-overflow-hidden tw-mt-8">
              <SliderComponent />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
